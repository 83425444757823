import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
// import { AiFillDislike, AiFillLike } from "react-icons/ai";
import { MdEdit } from "react-icons/md";
import { FaTrash } from "react-icons/fa";
import { TbCalendarRepeat } from "react-icons/tb";

import { ResponsiveTable, TransactionsTable, TransactionsItem } from "./styled";

import { useTransactions } from "../../../context/TransactionsContext";
import { useModal } from "../../../context/ModalContext";

// Services API
import { getTransactions, updateTransaction, deleteTransactionApi } from "../../../services/Transactions";

// Hooks
import { formatCurrency } from "../../../hooks/FormatCurrency";
import { formatDate } from "../../../hooks/FormatDate";
import { getClassType } from "../../../hooks/GetClassType";

// Modals
import { TransactionsModalAdd } from "../TransactionsModalAdd";
import { TransactionsModalEdit } from "../TransactionsModalEdit";
import useAuthStore from "../../../stores/AuthStore";

export const TransactionsList = () => {
    const { transactions, setTransactions } = useTransactions();
    const [transactionsLength, setTransactionsLength] = useState<Boolean>(false);
    const [message, setMessage] = useState("Carregando...");
    const [transactionForEdit, setTransactionForEdit] = useState({});
    const { modalTransactionsAdd_IsOpen, modalTransactionsEdit_IsOpen, modalTransactionsEdit_toggleModal } = useModal();

    const { logout } = useAuthStore();
    const navigate = useNavigate();

    const fetchTransactions = async (month = new Date().getMonth() + 1, year = new Date().getFullYear()) => {
        // let transactionsGetted = await getTransactions(month, year);
        await getTransactions(month, year)
            .then((response) => {
                setTransactions(response);
                console.log(transactions.length);

                if (response.length === 0) {
                    setMessage("Nenhuma transação encontrada. Cadastre a primeira!");
                    setTransactionsLength(false);
                } else {
                    setTransactionsLength(true);
                }
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    html: "Sessão expirada. <br /> Faça login novamente!",
                    confirmButtonColor: "#3D63DD",
                    confirmButtonText: "Fazer login",
                    width: "400px",
                    focusConfirm: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        logout();
                        navigate("/login");
                    }
                });
            });
    };

    const changeTransactionStatus = async (id: number, newStatus) => {
        let transaction = transactions.find((transaction) => transaction.id === id);

        if (transaction) {
            // let newTransactionsStatus = transaction.status === "not_paid" ? "paid" : "not_paid";

            await updateTransaction(id, {
                status: newStatus,
            });

            transaction.status = newStatus;

            setTransactions([...transactions]);

            const Toast = Swal.mixin({
                toast: true,
                position: "bottom",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                width: "450px",
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                },
            });
            Toast.fire({
                icon: "success",
                html: `O status da transação <b>"${transaction.title}"</b> foi alterado para ${newStatus}!`,
            });
        }
    };

    const deleteTransaction = async (id: number, title: string, isRecurrence: boolean) => {
        if (isRecurrence) {
            const inputOptions = new Promise((resolve) => {
                // setTimeout(() => {
                resolve({
                    this: "Excluir apenas este lançamento",
                    forward: "Excluir este e os próximos",
                });
                // }, 1000);
            });
            const { value: optionToDelete } = await Swal.fire({
                html: `Você tem certeza que deseja <br/> excluir a transação <b>"${title}?"</b>`,
                input: "radio",
                icon: "warning",
                inputOptions,
                width: "400px",
                showCancelButton: true,
                confirmButtonColor: "#3D63DD",
                cancelButtonColor: "#E52E4D",
                confirmButtonText: "Sim, deletar!",
                cancelButtonText: "Cancelar",
                inputValidator: (value) => {
                    if (!value) {
                        return "Você precisa escolher uma opção!";
                    }
                },
            });
            if (optionToDelete) {
                let timerInterval;
                Swal.fire({
                    title: "Deletando...",
                    html: "",
                    timer: 50000,
                    timerProgressBar: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                    willClose: () => {
                        clearInterval(timerInterval);
                    },
                }).then((result) => {});

                if (optionToDelete === "this") {
                    await deleteTransactionApi(id, true, true, false);
                } else {
                    await deleteTransactionApi(id, true, false, true);
                }

                let transactionsArrayUpdated = transactions.filter((account) => account.id !== id);
                setTransactions([...transactionsArrayUpdated]);

                const Toast = Swal.mixin({
                    toast: true,
                    position: "bottom",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    width: "450px",
                    didOpen: (toast) => {
                        toast.onmouseenter = Swal.stopTimer;
                        toast.onmouseleave = Swal.resumeTimer;
                    },
                });
                Toast.fire({
                    icon: "success",
                    html: `A conta <b>"${title}"</b> foi deletada!`,
                });
            }
        }

        if (!isRecurrence) {
            Swal.fire({
                title: "",
                html: `Você tem certeza que deseja excluir a transação <b>"${title}?"</b>`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3D63DD",
                cancelButtonColor: "#E52E4D",
                confirmButtonText: "Sim, deletar!",
                cancelButtonText: "Cancelar",
                width: "400px",
                focusConfirm: false,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let timerInterval;
                    Swal.fire({
                        title: "Deletando...",
                        html: "",
                        timer: 50000,
                        timerProgressBar: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        didOpen: () => {
                            Swal.showLoading();
                        },
                        willClose: () => {
                            clearInterval(timerInterval);
                        },
                    }).then((result) => {});

                    await deleteTransactionApi(id, false, false, false);

                    let transactionsArrayUpdated = transactions.filter((account) => account.id !== id);
                    setTransactions([...transactionsArrayUpdated]);

                    const Toast = Swal.mixin({
                        toast: true,
                        position: "bottom",
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        width: "450px",
                        didOpen: (toast) => {
                            toast.onmouseenter = Swal.stopTimer;
                            toast.onmouseleave = Swal.resumeTimer;
                        },
                    });
                    Toast.fire({
                        icon: "success",
                        html: `A conta <b>"${title}"</b> foi deletada!`,
                    });
                }
            });
        }
    };

    useEffect(() => {
        if (transactions.length === 0) {
            setTimeout(() => {
                setMessage("Nenhuma transação encontrada. Cadastre a primeira!");
            }, 2000);
            setTransactionsLength(false);
        } else {
            setTransactionsLength(true);
        }
    }, [transactions]);

    // useEffect(() => {
    //     fetchTransactions();
    // }, [setTransactions]);

    useEffect(() => {
        console.log(transactions.length);
        // setTransactions([]);
        if (transactions.length === 0) {
            fetchTransactions();
        } else if (parseInt(transactions[0].date.substring(5, 7)) !== new Date().getMonth() + 1) {
            fetchTransactions();
        }
    }, []);

    return (
        <div>
            {modalTransactionsAdd_IsOpen && <TransactionsModalAdd />}
            {modalTransactionsEdit_IsOpen && <TransactionsModalEdit transaction={transactionForEdit} />}

            <ResponsiveTable>
                <TransactionsTable>
                    <thead className={transactionsLength === true ? "visible" : "invisible"}>
                        <tr>
                            <th>Dia</th>
                            <th>Título</th>
                            <th>Conta</th>
                            <th>Categoria</th>
                            <th>Valor</th>
                            <th>Status</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactionsLength ? (
                            transactions.map((transaction) => (
                                <TransactionsItem key={transaction.id}>
                                    <td>{formatDate(transaction.date).substring(0, 5)}</td>
                                    <td>
                                        <div>
                                            {transaction.title}{" "}
                                            {transaction.isRecurrent ? (
                                                transaction.installmentTimes === 36 ? (
                                                    <TbCalendarRepeat />
                                                ) : (
                                                    `${transaction.installmentNumber} / ${transaction.installmentTimes}`
                                                )
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </td>
                                    <td>{transaction.account.name}</td>
                                    <td>{transaction.category.title}</td>
                                    <td>
                                        <span className={"value " + getClassType(transaction.type)}>{formatCurrency(transaction.amount)}</span>
                                    </td>
                                    <td className="changeStatus">
                                        {/*   */}
                                        {/* {transaction.status === "not_paid" ? <AiFillDislike /> : <AiFillLike className="paid" />} */}
                                        <select
                                            className={transaction.status}
                                            value={transaction.status}
                                            onChange={(e) => changeTransactionStatus(transaction.id, e.target.value)}
                                        >
                                            <option value="paid">Pago</option>
                                            <option value="not_paid">Não Pago</option>
                                            <option value="scheduled">Agendado</option>
                                        </select>
                                    </td>
                                    <td>
                                        <div>
                                            <MdEdit
                                                title="Editar"
                                                onClick={() => {
                                                    setTransactionForEdit(transaction);
                                                    modalTransactionsEdit_toggleModal();
                                                }}
                                                className="edit"
                                            />
                                            <FaTrash
                                                title="Excluir"
                                                onClick={() => {
                                                    deleteTransaction(transaction.id, transaction.title, transaction.isRecurrent);
                                                }}
                                            />
                                        </div>
                                    </td>
                                </TransactionsItem>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={5}>{message}</td>
                            </tr>
                        )}
                    </tbody>
                </TransactionsTable>
            </ResponsiveTable>
        </div>
    );
};
