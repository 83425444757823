import styled from "styled-components";

export const ResponsiveTable = styled.div`
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
`;

export const TransactionsTable = styled.table`
    width: 100%;
    border-spacing: 0 0.5rem;
    padding-bottom: 4rem;

    thead {
        &.invisible {
            display: none;
        }

        th {
            font-size: 1rem;
            color: ${(props) => props.theme.colors.primary};
            font-weight: 500;
            font-family: "Roboto", sans-serif;
            padding: 1rem;
            text-align: left;
            border-bottom: 1px solid ${(props) => props.theme.colors.gray};

            &:first-child {
                width: 30px;
                text-align: center;
            }

            /* 
            &:nth-child(3),
            &:last-child {
                width: 250px;
            } */

            &:nth-child(5),
            &:nth-child(6),
            &:last-child {
                text-align: center;
            }
        }
    }

    tbody {
        td {
            font-size: 1rem;
            color: ${(props) => props.theme.colors.text};
            font-weight: 400;
            font-family: "Roboto", sans-serif;
            padding: 1rem;
            border-bottom: 1px solid ${(props) => props.theme.colors.gray};

            &:first-child {
                border-radius: 0.25rem 0 0 0.25rem;
                font-weight: bold;
                font-size: 20px;
                text-align: center;
            }

            &:nth-child(2) {
                div {
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;

                    svg {
                        font-size: 20px;
                        opacity: 0.7;
                    }
                }
            }

            &:nth-child(5),
            &:last-child {
                text-align: center;
            }

            &.changeStatus {
                select {
                    padding: 5px;
                    color: white;
                    border: none;
                    text-align: center;
                    appearance: none;
                    min-width: 100px;
                    cursor: pointer;

                    &.paid {
                        background-color: ${(props) => props.theme.colors.green};
                        border-radius: 5px;
                    }

                    &.not_paid {
                        background-color: ${(props) => props.theme.colors.red};
                        border-radius: 5px;
                    }

                    &.scheduled {
                        background-color: ${(props) => props.theme.colors.yellow};
                        border-radius: 5px;
                    }
                }
            }

            &:last-child {
                border-radius: 0 0.25rem 0.25rem 0;

                div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 10px;
                }

                svg {
                    color: ${(props) => props.theme.colors.grayBlack};
                    font-size: 17px;
                    cursor: pointer;
                    transition: 0.5s all;

                    &.edit {
                        font-size: 22px;
                        margin-right: 8px;
                    }

                    &:hover {
                        opacity: 1;
                        color: #333;
                    }
                }
            }
        }
    }
`;

export const TransactionsItem = styled.tr`
    span.income {
        color: ${(props) => props.theme.colors.greenValue};
    }

    span.expense {
        color: ${(props) => props.theme.colors.red};
    }
`;
