import styled from "styled-components";

export const InternalPageHeader = styled.div`
    margin-bottom: 3rem;

    > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.5rem;
    }

    h1 {
        font-size: 20px;
        margin-bottom: 0;
    }

    hr {
        background: ${(props) => props.theme.colors.gray};
        opacity: 0.2;
    }

    button {
        color: ${(props) => props.theme.colors.white};
        border: none;
        background: ${(props) => props.theme.colors.primary};
        padding: 0.5rem;
        border-radius: ${(props) => props.theme.border.radiusSmall};
        cursor: pointer;
        transition: 0.5s all;
        font-size: 15px;

        &:hover {
            background: ${(props) => props.theme.colors.hover};
        }
    }
`;
