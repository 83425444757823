// Services API
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { FaTrash } from "react-icons/fa";
import Swal from "sweetalert2";

import { ResponsiveTable, TransactionsTable, TransactionsItem } from "./styled";

import { formatCurrency } from "../../../hooks/FormatCurrency";
import { InternalPagesHeader } from "../../../layout/InternalPages/Header";
import { getAccounts, deleteAccount } from "../../../services/AccountsServices";

import { useAccounts } from "../../../context/AccountsContext";
import { useModal } from "../../../context/ModalContext";
import useAuthStore from "../../../stores/AuthStore";

import { AccountsModalAdd } from "../AccountsModalAdd";
import { AccountsModalEdit } from "../AccountsModalEdit";

export const AccountsList = () => {
    const { accounts, setAccounts } = useAccounts();
    const [accountsLength, setAccountsLength] = useState<Boolean>(false);
    const [firstLoad, setFirstLoad] = useState<Boolean>(true);
    const [message, setMessage] = useState("Carregando...");
    const { modalAccountsAdd_IsOpen, modalAccountsAdd_toggleModal, modalAccountsEdit_IsOpen, modalAccountsEdit_toggleModal } = useModal();
    const [accountForEdit, setAccountForEdit] = useState({});

    const { logout } = useAuthStore();
    const navigate = useNavigate();

    const deleteItem = async (id: number, name: string) => {
        Swal.fire({
            title: "",
            html: `Você tem certeza que deseja excluir a conta bancária <b>"${name}?"</b>`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3D63DD",
            cancelButtonColor: "#E52E4D",
            confirmButtonText: "Sim, deletar!",
            cancelButtonText: "Cancelar",
            width: "400px",
            focusConfirm: false,
        }).then(async (result) => {
            if (result.isConfirmed) {
                let timerInterval;
                Swal.fire({
                    title: "Deletando...",
                    html: "",
                    timer: 50000,
                    timerProgressBar: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                    willClose: () => {
                        clearInterval(timerInterval);
                    },
                }).then((result) => {});

                await deleteAccount(id);

                Swal.close();

                let accountsArrayUpdated = accounts.filter((account) => account.id !== id);

                setAccounts([...accountsArrayUpdated]);

                const Toast = Swal.mixin({
                    toast: true,
                    position: "bottom",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    width: "450px",
                    didOpen: (toast) => {
                        toast.onmouseenter = Swal.stopTimer;
                        toast.onmouseleave = Swal.resumeTimer;
                    },
                });
                Toast.fire({
                    icon: "success",
                    html: `A conta <b>"${name}"</b> foi deletada!`,
                });
            }
        });
    };

    useEffect(() => {
        if (!firstLoad) {
            if (accounts.length === 0) {
                setMessage("Nenhuma transação encontrada. Cadastre a primeira!");
                setAccountsLength(false);
            } else {
                setAccountsLength(true);
            }
        }
    }, [accounts]);

    const fetchAccounts = async () => {
        await getAccounts()
            .then((response) => {
                let orderedBytitle = response.sort((a, b) => {
                    return a.name.localeCompare(b.name);
                });

                setAccounts(orderedBytitle);

                if (response.length === 0) {
                    setMessage("Nenhuma transação encontrada. Cadastre a primeira!");
                    setAccountsLength(false);
                } else {
                    setAccountsLength(true);
                }

                setFirstLoad(false);
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    html: "Sessão expirada. <br /> Faça login novamente!",
                    confirmButtonColor: "#3D63DD",
                    confirmButtonText: "Fazer login",
                    width: "400px",
                    focusConfirm: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        logout();
                        navigate("/login");
                    }
                });
            });
    };

    useEffect(() => {
        fetchAccounts();
    }, []);

    return (
        <>
            {modalAccountsAdd_IsOpen && <AccountsModalAdd />}
            {modalAccountsEdit_IsOpen && <AccountsModalEdit account={accountForEdit} />}

            <InternalPagesHeader>
                <div>
                    <h1>Contas bancárias</h1>
                    <button
                        onClick={() => {
                            modalAccountsAdd_toggleModal();
                        }}
                    >
                        Adicionar Conta
                    </button>
                </div>

                <hr />
            </InternalPagesHeader>

            <ResponsiveTable>
                <TransactionsTable>
                    <thead className={accountsLength === true ? "visible" : "invisible"}>
                        <tr>
                            <th>Título</th>
                            <th>Saldo inicial</th>
                            <th>Saldo Atual</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {accountsLength ? (
                            accounts.map((account) => (
                                <TransactionsItem key={account.id}>
                                    <td>
                                        <strong>{account.name}</strong>
                                    </td>
                                    <td>{formatCurrency(account.initialBalance)}</td>
                                    <td>{formatCurrency(account.balance)}</td>
                                    <td>
                                        <div>
                                            <MdEdit
                                                title="Editar"
                                                onClick={() => {
                                                    setAccountForEdit(account);
                                                    modalAccountsEdit_toggleModal();
                                                }}
                                                className="edit"
                                            />
                                            <FaTrash
                                                title="Excluir"
                                                onClick={() => {
                                                    deleteItem(account.id, account.name);
                                                }}
                                            />
                                        </div>
                                    </td>
                                </TransactionsItem>
                            ))
                        ) : (
                            <tr>
                                <td className="message" colSpan={5}>
                                    {message}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </TransactionsTable>
            </ResponsiveTable>
        </>
    );
};
