import React, { createContext, useState, useContext, ReactNode } from "react";

interface Category {
    id: number;
    title: string;
    type: "income" | "expense";
    deleted: boolean;
    createdAt: string;
    updatedAt: string;
    _count?: {
        transactions: number;
    };
}

interface CategoriesContextData {
    categories: Category[];
    setCategories: React.Dispatch<React.SetStateAction<Category[]>>;
}

const CategoriesContext = createContext<CategoriesContextData | undefined>(undefined);

export const CategoriesProvider = ({ children }: { children: ReactNode }) => {
    const [categories, setCategories] = useState<Category[]>([]);

    return <CategoriesContext.Provider value={{ categories, setCategories }}>{children}</CategoriesContext.Provider>;
};

export const useCategories = () => {
    const context = useContext(CategoriesContext);
    if (!context) {
        throw new Error("useCategories must be used within a CategoriesProvider");
    }
    return context;
};
