import { createContext, useContext, ReactNode, useState } from "react";

interface Account {
    id: number;
    institutionId: number;
    name: string;
    balance: number;
    initialBalance: number;
    status: string;
    createdAt: string;
    updatedAt: string;
    institution: {
        name: string;
    };
}

interface AccountsContextProps {
    accounts: Account[];
    setAccounts: React.Dispatch<React.SetStateAction<Account[]>>;
}

const AccountsContext = createContext<AccountsContextProps | undefined>(undefined);

export const AccountsProvider = ({ children }: { children: ReactNode }) => {
    const [accounts, setAccounts] = useState<Account[]>([]);

    return <AccountsContext.Provider value={{ accounts, setAccounts }}>{children}</AccountsContext.Provider>;
};

export const useAccounts = () => {
    const context = useContext(AccountsContext);

    if (!context) {
        throw new Error("useAccounts must be used within an AccountsProvider");
    }

    return context;
};
