import styled from "styled-components";
import { darken, transparentize } from "polished";

const textColor = (props) => props.theme.colors.text;
var grayColor = (props) => props.theme.colors.gray;

export const ModalContainer = styled.div`
    display: none;

    &.opened {
        display: block;
    }

    .transactionsModal {
        &__container {
            display: flex;
            justify-content: center;
            align-items: center;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 1000;
        }

        &__body {
            position: relative;
            width: 95%;
            max-width: 535px;
            /* min-height: 500px; */
            padding: 30px;
            background-color: ${(props) => props.theme.colors.white};
            border-radius: 10px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

            @media (min-width: 998px) {
                padding: 48px;
            }

            &--header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 1.5rem;

                h1 {
                    font-size: 20px;
                    color: ${(props) => props.theme.colors.text};
                    font-weight: bold;
                    margin-bottom: 0;
                }

                span {
                    /* position: absolute;
                    top: 10px;
                    right: 20px; */
                    color: #333;
                    font-size: 25px;
                    font-family: "Popins", sans-serif;
                    cursor: pointer;
                }
            }

            form {
                fieldset {
                    padding: 0;
                    margin: 0;
                    border: none;

                    & + fieldset {
                        margin-top: 1rem;
                    }

                    &.doubleInputs {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 1rem;
                    }

                    label {
                        font-size: 1rem;
                        display: block;
                        margin-bottom: 0.5rem;
                        opacity: 0.8;
                    }
                }

                input,
                textarea,
                select {
                    display: block;
                    padding: 0.7rem 1rem;
                    width: 100%;
                    background: ${(props) => props.theme.colors.white};
                    border-radius: ${(props) => props.theme.border.radius};
                    border: ${(props) => props.theme.border.style};

                    ::placeholder {
                        font-size: 16px;
                        color: ${(props) => props.theme.colors.text};
                    }

                    &.fullWidth {
                        max-width: 100%;
                    }
                }

                input[type="date"] {
                    /* border: none; */
                    box-sizing: border-box;
                    outline: 0;
                    padding: 0.75rem;
                    position: relative;
                    width: 100%;

                    &::-webkit-calendar-picker-indicator {
                        background: transparent;
                        bottom: 0;
                        color: transparent;
                        cursor: pointer;
                        height: auto;
                        left: 0;
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: auto;
                    }
                }

                option {
                    font-size: 16px;
                    color: ${(props) => props.theme.colors.text};
                }

                textarea {
                    height: 90px;
                }

                .isRecurrenceBtn {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    gap: 0.5rem;
                    margin-top: 1rem;
                    cursor: pointer;
                    max-width: max-content;
                    padding: 10px;
                    border-radius: ${(props) => props.theme.border.radius};
                    transition: 0.3s all;

                    &:hover,
                    &.active {
                        background: ${(props) => transparentize(0.6, grayColor(props))};
                    }
                }

                .isRecurrenceOptions {
                    margin-top: 1rem;
                    display: none;

                    &.active {
                        display: block;
                    }

                    > label {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 10px;

                        & + label {
                            margin-top: 0.5rem;
                        }

                        input {
                            width: max-content;
                        }

                        span {
                            margin: 0;
                        }
                    }
                }

                .isRecurrenceInstallments {
                    margin-top: 1rem;
                    display: none;

                    &.active {
                        display: block;
                    }

                    label {
                        display: block;
                        margin-bottom: 0.5rem;
                    }

                    span {
                        display: flex;
                        align-items: center;
                        gap: 0.5rem;
                        margin-top: 0.5rem;

                        svg {
                            font-size: 20px;
                        }
                    }
                }

                button.send {
                    display: block;
                    width: 100%;
                    margin-top: 1rem;
                    background: ${(props) => props.theme.colors.primary};
                    border-color: ${(props) => props.theme.colors.primary};
                    text-decoration: none;
                    transition: all 0.2s ease-in-out;
                    padding: 7px 30px;
                    border-radius: 5px;
                    white-space: normal;
                    cursor: pointer;

                    font-size: 1rem;
                    line-height: 1.5;
                    font-weight: 400;
                    color: white;

                    &:hover {
                        background: ${(props) => props.theme.colors.hover};
                        border-color: ${(props) => props.theme.colors.hover};
                    }
                }
            }
        }
    }
`;

export const TransactionTypeContainer = styled.div`
    margin: 1rem 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
`;

interface IRadioBoxProps {
    isactive: string;
    activecolor: "green" | "red";
}

const colors = {
    green: "#33CC95",
    red: "#E52E4D",
};

export const RadioBox = styled.button<IRadioBoxProps>`
    height: 4rem;
    border: 1px solid #d7d7d7;
    border-radius: 0.25rem;

    background: ${(props) => (props.isactive === "true" ? transparentize(0.2, colors[props.activecolor]) : "transparent")};

    display: flex;
    align-items: center;
    justify-content: center;

    transition: border-color 0.2s;
    cursor: pointer;

    &:hover {
        border-color: ${darken(0.1, "#d7d7d7")};
    }

    svg {
        font-size: 1.5rem;
        color: ${(props) => (props.isactive === "true" ? "white" : textColor)};

        &.income {
            color: ${(props) => (props.isactive === "true" ? "white" : colors.green)};
        }

        &.expense {
            color: ${(props) => (props.isactive === "true" ? "white" : colors.red)};
        }
    }

    span {
        display: inline-block;
        margin-left: 1rem;
        font-size: 1rem;
        font-family: "Roboto", sans-serif;
        color: ${(props) => (props.isactive === "true" ? "white" : textColor)};
    }
`;
