import { useState } from "react";
import { NumericFormat } from "react-number-format";
import Swal from "sweetalert2";

import { Modal } from "../../../layout/Modal";

import { useModal } from "../../../context/ModalContext";
import { useCategories } from "../../../context/CategoriesContext";

import { updateCategory } from "../../../services/Categories";

export const CategoriesModalEdit = (props) => {
    const { categories, setCategories } = useCategories();
    const { toggleModal } = useModal();

    // Account Info
    const [categoryTitle, setCategoryTitle] = useState<string>(props.category.title);
    const [categoryType, setCategoryType] = useState<string>(props.category.type);

    const handleFormSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        let timerInterval;
        Swal.fire({
            title: "Salvando...",
            html: "",
            timer: 50000,
            timerProgressBar: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
            willClose: () => {
                clearInterval(timerInterval);
            },
        }).then((result) => {});

        let dataForUpdate = {};

        if (categoryTitle !== props.category.title) {
            dataForUpdate["title"] = categoryTitle;
        }

        let accountUpdated = await updateCategory(props.category.id, dataForUpdate);

        let category = categories.find((account) => account.id === props.category.id);
        let index = categories.indexOf(category);
        categories[index] = accountUpdated[0];

        setCategories([...categories]);

        Swal.close();

        toggleModal();

        const Toast = Swal.mixin({
            toast: true,
            position: "bottom",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            width: "450px",
            didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
            },
        });
        Toast.fire({
            icon: "success",
            html: `A categoria <b>"${accountUpdated[0].title}"</b> foi atualizada!`,
        });
    };

    return (
        <>
            <Modal modalTitle="Editar categoria">
                <form onSubmit={handleFormSubmit}>
                    <fieldset>
                        <label htmlFor="categoryTitle">Título da categoria</label>
                        <input
                            type="text"
                            id="categoryTitle"
                            className="fullWidth"
                            value={categoryTitle}
                            onChange={(e) => setCategoryTitle(e.target.value)}
                        />
                    </fieldset>
                    <fieldset>
                        <label htmlFor="categoryType">Tipo:</label>
                        <select
                            className="fullWidth"
                            id="categoryType"
                            value={categoryType}
                            onChange={(e) => {
                                setCategoryType(e.target.value as "income" | "expense");
                            }}
                        >
                            <option value="income">Entrada</option>
                            <option value="expense">Saída</option>
                        </select>
                    </fieldset>
                    <button className="send">Salvar</button>
                </form>
            </Modal>
        </>
    );
};
