import { InternalPagesHeader } from "../../layout/InternalPages/Header";

export const ProfilePage = () => {
    return (
        <>
            <InternalPagesHeader>
                <div>
                    <h1>Configurações</h1>
                </div>
                <hr />
            </InternalPagesHeader>
        </>
    );
};
