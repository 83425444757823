import styled from "styled-components";

export const HeaderContainer = styled.header`
    padding: 0.8rem 0;
    background: ${(props) => props.theme.colors.white};
    /* box-shadow: 0 2px 3px 1px rgb(0 0 0 / 25%); */
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 10;
    margin-bottom: 4rem;

    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        gap: 1.5rem;

        @media (min-width: 998px) {
            flex-direction: row;
            gap: 0;
        }

        img {
            max-width: 150px;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            align-items: center;

            li {
                & + li {
                    margin-left: 1rem;
                }

                a {
                    color: ${(props) => props.theme.colors.primary};
                }
            }
        }

        > div {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            /* flex-direction: column; */
            flex-direction: row;

            /* @media (min-width: 998px) {
                flex-direction: row;
            } */

            svg {
                color: ${(props) => props.theme.colors.primary};
                font-size: 28px;
                cursor: pointer;
                transition: 0.5s all;

                &:hover {
                    color: ${(props) => props.theme.colors.hover};
                }
            }
        }
    }
`;

export const HeaderUserMenu = styled.div`
    position: relative;
    padding: 0;
    cursor: default;
    max-width: 100%;

    @media (min-width: 998px) {
        max-width: 150px;
        padding: 1rem;
    }

    &:hover {
        background: #f1f1f1;

        > div {
            opacity: 1;
            pointer-events: initial;
        }
    }

    > div {
        width: 100%;
        position: absolute;
        top: 100%;
        left: 0;
        transition: 0.5s all;
        background: #f1f1f1;
        padding: 1rem;
        opacity: 0;
        pointer-events: none;
        border-radius: 0 0 ${(props) => props.theme.border.radiusSmall} ${(props) => props.theme.border.radiusSmall};

        a {
            display: block;
            padding: 5px 0;
            cursor: pointer;

            font-size: 15px;
            color: ${(props) => props.theme.colors.text};
        }
    }
`;
