import { createContext, useContext, ReactNode, useState } from "react";

interface ModalContextProps {
    isOpen: boolean;
    modalAccountsAdd_IsOpen: boolean;
    modalAccountsEdit_IsOpen: boolean;
    modalTransactionsAdd_IsOpen: boolean;
    modalTransactionsEdit_IsOpen: boolean;
    toggleModal: () => void;
    modalAccountsAdd_toggleModal: () => void;
    modalAccountsEdit_toggleModal: () => void;
    modalTransactionsAdd_toggleModal: () => void;
    modalTransactionsEdit_toggleModal: () => void;
}

const ModalContext = createContext<ModalContextProps | undefined>(undefined);

export const ModalProvider = ({ children }: { children: ReactNode }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [modalAccountsAdd_IsOpen, setModalAccountsAdd_IsOpen] = useState(false);
    const [modalAccountsEdit_IsOpen, setModalAccountsEdit_IsOpen] = useState(false);
    const [modalTransactionsAdd_IsOpen, setModalTransactionsAdd_IsOpen] = useState(false);
    const [modalTransactionsEdit_IsOpen, setModalTransactionsEdit_IsOpen] = useState(false);

    const toggleModal = () => {
        setIsOpen(false);
        setModalAccountsAdd_IsOpen(false);
        setModalAccountsEdit_IsOpen(false);
        setModalTransactionsAdd_IsOpen(false);
        setModalTransactionsEdit_IsOpen(false);
    };

    const modalAccountsAdd_toggleModal = () => {
        setModalAccountsAdd_IsOpen((open) => !open);
    };

    const modalAccountsEdit_toggleModal = () => {
        setModalAccountsEdit_IsOpen((open) => !open);
    };

    const modalTransactionsAdd_toggleModal = () => {
        setModalTransactionsAdd_IsOpen((open) => !open);
    };

    const modalTransactionsEdit_toggleModal = () => {
        setModalTransactionsEdit_IsOpen((open) => !open);
    };

    return (
        <ModalContext.Provider
            value={{
                isOpen,
                modalAccountsAdd_IsOpen,
                modalAccountsEdit_IsOpen,
                modalTransactionsAdd_IsOpen,
                modalTransactionsEdit_IsOpen,
                toggleModal,
                modalAccountsAdd_toggleModal,
                modalAccountsEdit_toggleModal,
                modalTransactionsAdd_toggleModal,
                modalTransactionsEdit_toggleModal,
            }}
        >
            {children}
        </ModalContext.Provider>
    );
};

export const useModal = () => {
    const context = useContext(ModalContext);

    if (!context) {
        throw new Error("userModal must be userd within a ModalProvider");
    }
    return context;
};
