import { useEffect, useState } from "react";

import { SummaryContainer } from "./styled";

import { useTransactions } from "../../../context/TransactionsContext";
import { getAccounts } from "../../../services/AccountsServices";

import { getTotalIncome, getTotalExpenses, getTotal } from "../../../hooks/GetTotals";
import { formatCurrency } from "../../../hooks/FormatCurrency";

export const Summary = () => {
    const { transactions, setTransactions } = useTransactions();
    const [total, setTotal] = useState(0);
    const [forecast, setForecast] = useState(0);
    const [loading, setLoading] = useState(true);
    const [message] = useState("Carregando...");

    let totalIncome = getTotalIncome(transactions);
    let totalExpense = getTotalExpenses(transactions);

    const fetchAccounts = async () => {
        if (transactions.length > 0) {
            setTotal(0);
            setForecast(0);
            setLoading(true);

            await getAccounts()
                .then((response) => {
                    let orderedBytitle = response.sort((a, b) => {
                        return a.name.localeCompare(b.name);
                    });

                    if (orderedBytitle.length > 0) {
                        let totalBalance = orderedBytitle.reduce((acc, account) => {
                            if (account.id === 1 || account.id === 8) {
                                return acc + account.balance;
                            }
                            return acc;
                            // return acc + account.balance;
                        }, 0);

                        let { total, forecast } = getTotal(transactions, totalBalance);

                        setTotal(total);
                        setForecast(forecast);
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    useEffect(() => {
        fetchAccounts();
    }, [transactions]);

    return (
        <SummaryContainer>
            <div>
                <span className="title">Entradas</span>
                <span className="value">{formatCurrency(totalIncome.total)}</span>

                <small>Previsto: {formatCurrency(totalIncome.forecast)}</small>
            </div>
            <div>
                <span className="title">Saídas</span>
                <span className="value">{formatCurrency(totalExpense.total)}</span>
                <small>Previsto: {formatCurrency(totalExpense.forecast)}</small>
            </div>
            <div className="total">
                <span className="title">Saldo Geral</span>
                <span className="value">{loading ? "..." : formatCurrency(total)}</span>
                <small>Previsto: {loading ? message : formatCurrency(forecast)}</small>
            </div>
        </SummaryContainer>
    );
};
