import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import Swal from "sweetalert2";

import { Modal } from "../../../layout/Modal";

import { useModal } from "../../../context/ModalContext";
import { useAccounts } from "../../../context/AccountsContext";

import { saveAccount } from "../../../services/AccountsServices";

export const AccountsModalAdd = () => {
    const { accounts, setAccounts } = useAccounts();
    const { toggleModal } = useModal();

    // Account Info
    const [accountName, setAccountName] = useState<string>("");
    const [accountInitialBalance, setAccountInitialBalance] = useState<string>("0");

    const handleFormSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        let timerInterval;
        Swal.fire({
            title: "Salvando...",
            html: "",
            timer: 50000,
            timerProgressBar: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
            willClose: () => {
                clearInterval(timerInterval);
            },
        });

        let accountAdded = await saveAccount({
            name: accountName,
            initialBalance: Number(accountInitialBalance),
        });

        let allAccounts = [...accounts, accountAdded];

        let orderedBytitle = allAccounts.sort((a, b) => {
            return a.name.localeCompare(b.name);
        });

        setAccounts(orderedBytitle);

        Swal.close();

        toggleModal();

        const Toast = Swal.mixin({
            toast: true,
            position: "bottom",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            width: "450px",
            didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
            },
        });
        Toast.fire({
            icon: "success",
            html: `A conta <b>"${accountAdded.name}"</b> foi adicionada!`,
        });
    };

    return (
        <>
            <Modal modalTitle="Adicionar Conta">
                <form onSubmit={handleFormSubmit}>
                    <fieldset>
                        <label htmlFor="accountName">Nome da Conta:</label>
                        <input
                            type="text"
                            id="accountName"
                            className="fullWidth"
                            placeholder="Ex.: Santander Pessoa Física"
                            value={accountName}
                            onChange={(e) => setAccountName(e.target.value)}
                        />
                    </fieldset>
                    <fieldset>
                        <label htmlFor="accountInitialBalance">Saldo Inicial:</label>
                        <NumericFormat
                            value={accountInitialBalance}
                            onValueChange={(values) => {
                                const { value } = values;
                                setAccountInitialBalance(value);
                            }}
                            required
                            placeholder={""}
                            allowLeadingZeros={false}
                            allowNegative={false}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            decimalSeparator={","}
                            allowedDecimalSeparators={["."]}
                            thousandSeparator="."
                            prefix={"R$ "}
                            className="fullWidth"
                        />
                    </fieldset>
                    <button className="send">Salvar</button>
                </form>
            </Modal>
        </>
    );
};
