import { ModalContainer } from "./styled";

// Context
import { useModal } from "../../context/ModalContext";

export const Modal = ({ children, modalTitle }) => {
    const { isOpen, modalAccountsAdd_IsOpen, modalAccountsEdit_IsOpen, toggleModal } = useModal();

    const verifyToggleModal = () => {
        if (isOpen || modalAccountsAdd_IsOpen || modalAccountsEdit_IsOpen) {
            return true;
        }
    };

    return (
        <ModalContainer className={verifyToggleModal ? "opened" : ""}>
            <div className="transactionsModal__container">
                <div className="transactionsModal__body">
                    <div className="transactionsModal__body--header">
                        <h1>{modalTitle}</h1>
                        <span
                            onClick={() => {
                                toggleModal();
                            }}
                        >
                            x
                        </span>
                    </div>
                    {children}
                </div>
            </div>
        </ModalContainer>
    );
};
