import styled from "styled-components";

export const Container = styled.div`
    /* padding: 0; */
`;

export const ContainerBody = styled.div`
    background: ${(props) => props.theme.colors.white};
    border-radius: ${(props) => props.theme.border.radius};
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    padding: 2.5rem 1.5rem;
    margin-bottom: 2.5rem;

    > .container {
        padding: 0;
    }
`;
