import { api } from "./InstanceAxios";

export interface Account {
    id: number;
    institutionId: number;
    name: string;
    balance: number;
    initialBalance: number;
    status: string;
    createdAt: string;
    updatedAt: string;
    institution: {
        name: string;
    };
}

interface AccountSave {
    name: string;
    initialBalance: number;
}

export async function getAccounts(): Promise<Account[]> {
    let accounts = [];
    const token = localStorage.getItem("accessToken");

    await api
        .get(`accounts`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(async (response) => {
            accounts = await response.data;
        })
        .then((error) => {
            return error;
        });

    return accounts;
}

export async function saveAccount(account: AccountSave) {
    let accountAdded: Account;
    const token = localStorage.getItem("accessToken");

    await api
        .post("accounts", account, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(async (response) => {
            accountAdded = response.data;
        })
        .then((error) => {
            return error;
        });

    return accountAdded;
}

export async function updateTransaction(id: number, data: {}): Promise<any> {
    let accountUpdated = [];
    const token = localStorage.getItem("accessToken");

    delete data["id"];

    await api
        .patch(
            "accounts",
            {
                id: id,
                data: data,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )
        .then(async (response) => {
            accountUpdated.push(response.data.data);
        })
        .then((error) => {
            return error;
        });

    return accountUpdated;
}

export async function deleteAccount(id: number) {
    const token = localStorage.getItem("accessToken");

    await api
        .delete("accounts", {
            data: {
                id: id,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error;
        });
}
