// import Swal from "sweetalert2";
import { orderByDate } from "../hooks/OrderByDate";
// import { useNavigate } from "react-router-dom";

import { api } from "./InstanceAxios";
// import useAuthStore from "../stores/AuthStore";

interface ITransaction {
    id: number;
    title: string;
    description: string;
    amount: number;
    type: "income" | "expense";
    categoryId: number;
    date: string;
    status: "paid" | "not_paid" | "scheduled";
    isRecurrent: boolean;
    accountId: number;
    installmentNumber: number;
    installmentTimes: number;
    account: {
        name: string;
    };
    category: {
        title: string;
    };
}

interface ITransactionSave {
    id: number;
    title: string;
    description: string;
    amount: number;
    type: "income" | "expense";
    categoryId: number;
    date: string;
    accountId: number;
    status: "paid" | "not_paid" | "scheduled";
}

export async function getTransactions(month = new Date().getMonth() + 1, year = new Date().getFullYear()): Promise<ITransaction[]> {
    let transactions = [];
    const token = localStorage.getItem("accessToken");

    await api
        .get(`transactions?month=${month}&year=${year}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(async (response) => {
            transactions = await orderByDate(response.data);
        })
        .then((error) => {
            return error;
        });

    return transactions;
}

export async function saveTransaction(transaction: ITransactionSave) {
    let transactionAdded = [];
    const token = localStorage.getItem("accessToken");

    delete transaction["id"];

    await api
        .post("transactions", transaction, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(async (response) => {
            transactionAdded.push(response.data);
        })
        .then((error) => {
            return error;
        });

    return transactionAdded;
}

export async function updateTransaction(id: number, data: {}): Promise<any> {
    let transactionUpdated = [];
    const token = localStorage.getItem("accessToken");

    delete data["id"];

    await api
        .patch(
            "transactions",
            {
                id: id,
                data: data,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )
        .then(async (response) => {
            transactionUpdated.push(response.data.data);
        })
        .then((error) => {
            return error;
        });

    return transactionUpdated;
}

export async function deleteTransactionApi(id: number, isRecurrence: boolean, deleteThis: boolean, deleteForward: boolean): Promise<any> {
    let transactions = [];
    const token = localStorage.getItem("accessToken");

    await api
        .delete("transactions", {
            data: {
                id: id,
                isRecurrence,
                deleteThis,
                deleteForward,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(async (response) => {
            transactions = response.data;
        })
        .then((error) => {
            return error;
        });

    return transactions;
}
