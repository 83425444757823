import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { NumericFormat } from "react-number-format";

import { FaRegArrowAltCircleUp, FaRegArrowAltCircleDown } from "react-icons/fa";
// import { LuRepeat2 } from "react-icons/lu";
// import { IoIosInformationCircleOutline } from "react-icons/io";
// import { AiFillDislike, AiFillLike } from "react-icons/ai";

import { RadioBox, TransactionTypeContainer } from "../TransactionsModalAdd/styled";

import { Modal } from "../../../layout/Modal";

// import { formatCurrency } from "../../../hooks/FormatCurrency";

import { useModal } from "../../../context/ModalContext";
import { useTransactions } from "../../../context/TransactionsContext";
import { useAccounts } from "../../../context/AccountsContext";
import { useCategories } from "../../../context/CategoriesContext";

import { updateTransaction } from "../../../services/Transactions";
import { getAccounts } from "../../../services/AccountsServices";
import { getCategories } from "../../../services/Categories";

export const TransactionsModalEdit = (props) => {
    const { toggleModal } = useModal();

    const { transactions, setTransactions } = useTransactions();
    const { accounts, setAccounts } = useAccounts();
    const { categories, setCategories } = useCategories();

    const [title, setTitle] = useState(props.transaction.title);
    const [amount, setAmount] = useState(props.transaction.amount);
    const [category, setCategory] = useState(props.transaction.categoryId || 0);
    const [type, setType] = useState<"income" | "expense">(props.transaction.type || "expense");
    const [date, setDate] = useState(props.transaction.date || new Date().toISOString().substring(0, 10));
    const [status, setStatus] = useState<"paid" | "not_paid" | "scheduled">(props.transaction.status);
    const [description] = useState(props.transaction.description || "");
    const [accountId, setAccountId] = useState(props.transaction.accountId || (accounts.length > 0 ? accounts[0].id : 0));

    const [isRecurrent, setIsRecurrent] = useState(props.transaction.isRecurrent || false);
    const [times, setTimes] = useState(props.transaction.times || 1);

    useEffect(() => {
        const fetchData = async () => {
            if (accounts.length === 0) {
                await getAccounts().then((accounts) => {
                    setAccounts(accounts);
                    setAccountId(props.transaction.accountId || accounts[0].id);
                });
            }

            if (categories.length === 0) {
                await getCategories().then((categories) => {
                    setCategories(categories);
                    setCategory(props.transaction.categoryId || categories[0].id);
                });
            }
        };
        fetchData();
    }, []);

    // Submit
    const handleSubmit = async (Event: React.FormEvent) => {
        Event.preventDefault();

        let timerInterval;
        Swal.fire({
            title: "Salvando...",
            html: "",
            timer: 50000,
            timerProgressBar: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
            willClose: () => {
                clearInterval(timerInterval);
            },
        }).then((result) => {});

        const updatedFields = {};

        if (title !== props.transaction.title) updatedFields["title"] = title;
        if (amount !== props.transaction.amount) updatedFields["amount"] = Number(amount);
        if (category !== props.transaction.categoryId) updatedFields["categoryId"] = Number(category);
        if (type !== props.transaction.type) updatedFields["type"] = type;
        if (date !== props.transaction.date) updatedFields["date"] = new Date(date + "T05:00:00.000Z").toISOString();
        updatedFields["status"] = status;
        if (description !== props.transaction.description) updatedFields["description"] = description;
        if (accountId !== props.transaction.accountId) updatedFields["accountId"] = accountId;
        if (isRecurrent !== props.transaction.isRecurrent) updatedFields["isRecurrent"] = isRecurrent;

        let transactionUpdated = await updateTransaction(props.transaction.id, updatedFields);

        let transaction = transactions.find((transaction) => transaction.id === props.transaction.id);
        let index = transactions.indexOf(transaction);
        transactions[index] = transactionUpdated[0];
        setTransactions([...transactions]);

        Swal.close();

        toggleModal();

        const Toast = Swal.mixin({
            toast: true,
            position: "bottom",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            width: "450px",
            didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
            },
        });
        Toast.fire({
            icon: "success",
            html: `A transação <b>"${transactionUpdated[0].title}"</b> foi atualizada!`,
        });
    };

    return (
        <>
            <Modal modalTitle="Editar Transação">
                <form
                    onSubmit={(Event) => {
                        handleSubmit(Event);
                    }}
                >
                    <TransactionTypeContainer>
                        <RadioBox
                            type="button"
                            onClick={() => {
                                setType("income");
                            }}
                            isactive={type === "income" ? "true" : "false"}
                            activecolor="green"
                        >
                            <FaRegArrowAltCircleUp className="income" />
                            <span>Entrada</span>
                        </RadioBox>
                        <RadioBox
                            type="button"
                            onClick={() => {
                                setType("expense");
                            }}
                            isactive={type === "expense" ? "true" : "false"}
                            activecolor="red"
                        >
                            <FaRegArrowAltCircleDown className="expense" />
                            <span>Saída</span>
                        </RadioBox>
                    </TransactionTypeContainer>
                    <fieldset className="doubleInputs">
                        <input
                            type="text"
                            placeholder="Título"
                            value={title}
                            onChange={(e) => {
                                setTitle(e.target.value);
                            }}
                            className="fullWidth"
                        />
                        <NumericFormat
                            value={amount}
                            onValueChange={(values) => {
                                const { value } = values;
                                setAmount(value);
                            }}
                            required
                            placeholder={"Valor"}
                            allowLeadingZeros={false}
                            allowNegative={false}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            decimalSeparator={","}
                            allowedDecimalSeparators={["."]}
                            thousandSeparator="."
                            prefix={"R$ "}
                        />
                    </fieldset>
                    <fieldset className="doubleInputs">
                        <input
                            type="date"
                            placeholder="Data"
                            value={date.split("T")[0]} // Convert date to the format supported by input type="date"
                            onChange={(e) => {
                                setDate(e.target.value);
                            }}
                        />
                        <select value={status} onChange={(e) => setStatus(e.target.value as "paid" | "not_paid" | "scheduled")}>
                            <option value="not_paid">Não Pago</option>
                            <option value="paid">Pago</option>
                            <option value="scheduled">Agendado</option>
                        </select>
                    </fieldset>
                    <fieldset className="doubleInputs">
                        <select
                            value={accountId}
                            onChange={(e) => {
                                setAccountId(Number(e.target.value));
                            }}
                        >
                            {accounts.map((account) => (
                                <option key={account.id} value={account.id}>
                                    {account.name}
                                </option>
                            ))}
                        </select>
                        <select
                            value={category}
                            onChange={(e) => {
                                setCategory(e.target.value);
                            }}
                        >
                            {categories.map((category) => {
                                if (category.type === type) {
                                    return (
                                        <option key={category.id} value={category.id}>
                                            {category.title}
                                        </option>
                                    );
                                }
                                return null;
                            })}
                        </select>
                    </fieldset>
                    <button className="send">Salvar</button>
                </form>
            </Modal>
        </>
    );
};
