import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { HeaderContainer, HeaderUserMenu } from "./styled";

import useAuthStore from "../../stores/AuthStore";

import { getUser } from "../../services/User";

import Logo from "../../assets/logo.png";

export const Header = () => {
    const [username, setUsername] = useState("");

    const { logout } = useAuthStore();
    const navigate = useNavigate();

    function toggleLogOut() {
        logout();
        navigate("/login");
    }

    useEffect(() => {
        getUser()
            .then((response) => {
                setUsername(response[0].name);
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    html: "Sessão expirada. <br /> Faça login novamente!",
                    confirmButtonColor: "#3D63DD",
                    confirmButtonText: "Fazer login",
                    width: "400px",
                    focusConfirm: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        toggleLogOut();
                    }
                });
            });
    }, []);

    return (
        <>
            <HeaderContainer>
                <div className="container">
                    <img src={Logo} alt="Logo Finances App" />

                    <ul>
                        <li>
                            <Link to="/">Dashboard</Link>
                        </li>
                        <li>
                            <Link to="/transactions">Transações</Link>
                        </li>
                        {/* <li>
                            <Link to="/contacts">Contatos</Link>
                        </li> */}
                    </ul>

                    <div>
                        <HeaderUserMenu>
                            {username && (
                                <>
                                    Bem-vindo(a), <strong>{username}</strong>
                                </>
                            )}

                            <div>
                                <Link to="/accounts">Contas bancárias</Link>
                                <Link to="/categories">Categorias</Link>
                                <Link to="/">Meu Plano</Link>
                                <Link to="/profile">Configurações</Link>
                                <a onClick={() => toggleLogOut()}> Logout</a>
                            </div>
                        </HeaderUserMenu>
                    </div>
                </div>
            </HeaderContainer>
        </>
    );
};
