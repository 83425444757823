export async function orderByDate(transactions: any) {
    let ordered = transactions.sort((a, b) => {
        const dateComparison = new Date(a.date).getTime() - new Date(b.date).getTime();
        if (dateComparison !== 0) {
            return dateComparison;
        }
        return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    });

    return ordered;
}
