import { useState } from "react";
import Swal from "sweetalert2";

import { Modal } from "../../../layout/Modal";

import { useModal } from "../../../context/ModalContext";
import { useCategories } from "../../../context/CategoriesContext";

import { saveCategory } from "../../../services/Categories";

export const CategoriesModalAdd = () => {
    const { categories, setCategories } = useCategories();
    const { toggleModal } = useModal();

    // Account Info
    const [categoryTitle, setCategoryTitle] = useState<string>("");
    const [categoryType, setCategoryType] = useState<"income" | "expense">("income");

    const handleFormSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        let timerInterval;
        Swal.fire({
            title: "Salvando...",
            html: "",
            timer: 50000,
            timerProgressBar: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
            willClose: () => {
                clearInterval(timerInterval);
            },
        });

        let accountAdded = await saveCategory({
            title: categoryTitle,
            type: categoryType,
        });

        let allCategories = [...categories, accountAdded];

        let orderedBytitle = allCategories.sort((a, b) => {
            return a.title.localeCompare(b.title);
        });

        setCategories(orderedBytitle);

        Swal.close();

        toggleModal();

        const Toast = Swal.mixin({
            toast: true,
            position: "bottom",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            width: "450px",
            didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
            },
        });
        Toast.fire({
            icon: "success",
            html: `A categoria <b>"${accountAdded.title}"</b> foi adicionada!`,
        });
    };

    return (
        <>
            <Modal modalTitle="Adicionar Categoria">
                <form onSubmit={handleFormSubmit}>
                    <fieldset>
                        <label htmlFor="categoryTitle">Título da categoria:</label>
                        <input
                            type="text"
                            id="categoryTitle"
                            className="fullWidth"
                            placeholder="Ex.: Doações"
                            value={categoryTitle}
                            onChange={(e) => setCategoryTitle(e.target.value)}
                        />
                    </fieldset>
                    <fieldset>
                        <label htmlFor="categoryType">Tipo:</label>
                        <select
                            className="fullWidth"
                            id="categoryType"
                            onChange={(e) => {
                                setCategoryType(e.target.value as "income" | "expense");
                            }}
                        >
                            <option value="income">Entrada</option>
                            <option value="expense">Saída</option>
                        </select>
                    </fieldset>
                    <button className="send">Salvar</button>
                </form>
            </Modal>
        </>
    );
};
