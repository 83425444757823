import "./App.css";

import { RouterProvider } from "react-router-dom";

import { TransactionsProvider } from "./context/TransactionsContext";
import { ModalProvider } from "./context/ModalContext";
import { AccountsProvider } from "./context/AccountsContext";
import { CategoriesProvider } from "./context/CategoriesContext";

import { router } from "./Routes";

function App() {
    return (
        <AccountsProvider>
            <TransactionsProvider>
                <CategoriesProvider>
                    <ModalProvider>
                        <RouterProvider router={router}></RouterProvider>
                    </ModalProvider>
                </CategoriesProvider>
            </TransactionsProvider>
        </AccountsProvider>
    );
}

export default App;
