import { Summary } from "../../components/Transactions/Summary";
import { TransactionsNavigator } from "../../components/Transactions/TransactionsNavigator";
import { TransactionsList } from "../../components/Transactions/TransactionsList";

import { InternalPagesHeader } from "../../layout/InternalPages/Header";

import { useModal } from "../../context/ModalContext";

export const TransactionsPage = () => {
    const { modalTransactionsAdd_toggleModal } = useModal();

    return (
        <>
            <InternalPagesHeader>
                <div>
                    <h1>Transações</h1>
                    <button onClick={modalTransactionsAdd_toggleModal}>Adicionar Transação</button>
                </div>
                <hr />
            </InternalPagesHeader>
            <TransactionsNavigator />
            <Summary />
            <TransactionsList />
        </>
    );
};
