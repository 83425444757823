import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { NumericFormat } from "react-number-format";

import { FaRegArrowAltCircleUp, FaRegArrowAltCircleDown } from "react-icons/fa";
import { LuRepeat2 } from "react-icons/lu";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { AiFillDislike, AiFillLike } from "react-icons/ai";

import { RadioBox, TransactionTypeContainer } from "./styled";

import { Modal } from "../../../layout/Modal";

import { formatCurrency } from "../../../hooks/FormatCurrency";

import { useModal } from "../../../context/ModalContext";
import { useTransactions } from "../../../context/TransactionsContext";
import { useAccounts } from "../../../context/AccountsContext";
import { useCategories } from "../../../context/CategoriesContext";

import { saveTransaction } from "../../../services/Transactions";
import { getAccounts } from "../../../services/AccountsServices";
import { getCategories } from "../../../services/Categories";

export const TransactionsModalAdd = () => {
    const { toggleModal } = useModal();

    const navigate = useNavigate();

    const { transactions, setTransactions } = useTransactions();
    const { accounts, setAccounts } = useAccounts();
    const { categories, setCategories } = useCategories();

    const [title, setTitle] = useState("");
    const [amount, setAmount] = useState("");
    const [category, setCategory] = useState("");
    const [type, setType] = useState<"income" | "expense">("expense");
    const [date, setDate] = useState(new Date().toISOString().substring(0, 10));
    const [status, setStatus] = useState<"paid" | "not_paid" | "scheduled">("not_paid");
    const [description] = useState("");
    const [accountId, setAccountId] = useState(accounts.length > 0 ? accounts[0].id : 0);

    const [isRecurrent, setIsRecurrent] = useState(false);
    const [recurrenceAmount, setRecurrenceAmount] = useState(0);
    const [recurrenceStart, setRecurrenceStart] = useState(new Date());
    const [recurrenceEnd, setRecurrenceEnd] = useState(new Date());
    const [installmentValue, setInstallmentValue] = useState("");
    const [times, setTimes] = useState(1);
    const [done, setDone] = useState(0);
    const [recurrenceType, setRecurrenceType] = useState<"finite" | "infinite">("finite");
    const [periodicity, setPeriodicity] = useState<"monthly" | "bimonthly" | "quarterly" | "semiannual" | "annual">("monthly");
    const [recurrenceStatus, setRecurrenceStatus] = useState<"completed" | "canceled" | "active">("active");
    const [inInstallments, setInInstallments] = useState(false);

    useEffect(() => {
        if (accounts.length === 0) {
            getAccounts().then((accounts) => {
                if (accounts.length > 0) {
                    setAccounts(accounts);
                    setAccountId(accounts[0].id);
                } else {
                    alert("Você precisa ter uma conta cadastrada para adicionar uma transação!");
                    navigate("/accounts");
                }
            });
        }

        if (categories.length === 0) {
            getCategories().then((categories) => {
                setCategories(categories);
            });
        }
    }, []);

    const handleRecurrenceType = async (type) => {
        if (type === "infinite") {
            setInInstallments(false);

            setRecurrenceAmount(Number(amount));
            setRecurrenceStart(new Date(date + "T05:00:00.000Z"));
            setRecurrenceEnd(new Date(date + "T05:00:00.000Z"));
            setInstallmentValue(amount);
            setTimes(36);
            setDone(0);
            setRecurrenceType("infinite");
            setPeriodicity("monthly");
            setRecurrenceStatus("active");
        } else {
            setInInstallments(true);

            setRecurrenceAmount(Number(amount));
            setRecurrenceStart(new Date(date + "T05:00:00.000Z"));
            setRecurrenceEnd(new Date(date + "T05:00:00.000Z"));
            setInstallmentValue(String(Number(amount) / times));
            setTimes(1);
            setDone(0);
            setRecurrenceType("finite");
            setPeriodicity("monthly");
            setRecurrenceStatus("active");
        }
    };

    const handleRecurrenceInstallmentValue = (times) => {
        setTimes(Number(times));
        setInstallmentValue(String(Number(amount) / times));
    };

    useEffect(() => {
        handleRecurrenceInstallmentValue(times);
    }, [amount]);

    const handleSubmit = async (Event: React.FormEvent) => {
        Event.preventDefault();

        let timerInterval;
        Swal.fire({
            title: "Salvando...",
            html: "",
            timer: 50000,
            timerProgressBar: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
            willClose: () => {
                clearInterval(timerInterval);
            },
        }).then((result) => {});

        let dateForBd = new Date(date + "T05:00:00.000Z").toISOString();

        let newTransactionData = {
            id: 0,
            title,
            description,
            amount: Number(amount.replace(",", ".")),
            type,
            categoryId: Number(category),
            date: dateForBd.toString(),
            status,
            accountId,
            isRecurrent,
        };

        newTransactionData["recurrenceData"] = {
            recurrenceAmount: recurrenceAmount,
            recurrenceStart: recurrenceStart,
            recurrenceEnd: recurrenceEnd,
            installmentValue: Number(installmentValue),
            times: times,
            done: done,
            type: recurrenceType,
            periodicity: periodicity,
            status: recurrenceStatus,
        };

        let transactionAddedd = await saveTransaction(newTransactionData);

        setTransactions([...transactions, ...transactionAddedd]);

        Swal.close();

        toggleModal();

        const Toast = Swal.mixin({
            toast: true,
            position: "bottom",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            width: "450px",
            didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
            },
        });
        Toast.fire({
            icon: "success",
            html: `A transação <b>"${transactionAddedd[0].title}"</b> foi adicionada!`,
        });
    };

    return (
        <>
            <Modal modalTitle="Adicionar Transação">
                <form
                    onSubmit={(Event) => {
                        handleSubmit(Event);
                    }}
                >
                    <TransactionTypeContainer>
                        <RadioBox
                            type="button"
                            onClick={() => {
                                setType("income");
                            }}
                            isactive={type === "income" ? "true" : "false"}
                            activecolor="green"
                        >
                            <FaRegArrowAltCircleUp className="income" />
                            <span>Entrada</span>
                        </RadioBox>
                        <RadioBox
                            type="button"
                            onClick={() => {
                                setType("expense");
                            }}
                            isactive={type === "expense" ? "true" : "false"}
                            activecolor="red"
                        >
                            <FaRegArrowAltCircleDown className="expense" />
                            <span>Saída</span>
                        </RadioBox>
                    </TransactionTypeContainer>
                    <fieldset className="doubleInputs">
                        <input
                            type="text"
                            placeholder="Título"
                            value={title}
                            onChange={(e) => {
                                setTitle(e.target.value);
                            }}
                            className="fullWidth"
                        />
                        <NumericFormat
                            value={amount}
                            onValueChange={(values) => {
                                const { value } = values;
                                setAmount(value);
                            }}
                            required
                            placeholder={"Valor"}
                            allowLeadingZeros={false}
                            allowNegative={false}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            decimalSeparator={","}
                            allowedDecimalSeparators={["."]}
                            thousandSeparator="."
                            prefix={"R$ "}
                        />
                    </fieldset>
                    <fieldset className="doubleInputs">
                        <input
                            type="date"
                            placeholder="Data"
                            value={date}
                            onChange={(e) => {
                                setDate(e.target.value);
                            }}
                        />
                        <select onChange={(e) => setStatus(e.target.value as "paid" | "not_paid" | "scheduled")}>
                            <option value="not_paid">Não Pago</option>
                            <option value="paid">Pago</option>
                            <option value="scheduled">Agendado</option>
                        </select>
                    </fieldset>
                    <fieldset className="doubleInputs">
                        <select
                            value={accountId}
                            onChange={(e) => {
                                setAccountId(Number(e.target.value));
                            }}
                        >
                            {accounts.map((account) => (
                                <option key={account.id} value={account.id}>
                                    {account.name}
                                </option>
                            ))}
                        </select>
                        <select
                            value={category}
                            onChange={(e) => {
                                setCategory(e.target.value);
                            }}
                        >
                            {categories.map((category) => {
                                if (category.type === type) {
                                    return (
                                        <option key={category.id} value={category.id}>
                                            {category.title}
                                        </option>
                                    );
                                }
                                return null;
                            })}
                        </select>
                    </fieldset>
                    <div className={`isRecurrenceBtn ${isRecurrent ? "active" : ""}`} onClick={() => setIsRecurrent(!isRecurrent)}>
                        <LuRepeat2 />
                        <span>Repetir</span>
                    </div>
                    <div className={`isRecurrenceOptions ${isRecurrent ? "active" : ""}`}>
                        <label htmlFor="transactionFixed">
                            <input
                                type="radio"
                                name="Fixo"
                                id="transactionFixed"
                                onChange={() => {
                                    handleRecurrenceType("infinite");
                                }}
                            />
                            <span>Despesa fixa</span>
                        </label>
                        <label htmlFor="transactionFixedTwo">
                            <input
                                type="radio"
                                name="Fixo"
                                id="transactionFixedTwo"
                                onChange={() => {
                                    handleRecurrenceType("finite");
                                }}
                            />
                            <span>Despesa parcelada</span>
                        </label>
                    </div>
                    <div className={`isRecurrenceInstallments ${isRecurrent && inInstallments ? "active" : ""}`}>
                        <label htmlFor="installmentsValues">Número de parcelas</label>
                        <input
                            id="installmentsValues"
                            type="number"
                            placeholder="Número de parcelas"
                            min={1}
                            value={times}
                            onChange={(e) => {
                                handleRecurrenceInstallmentValue(e.target.value);
                            }}
                        />
                        <span>
                            <IoIosInformationCircleOutline />
                            Será gerado {times} parcelas de {formatCurrency(Number(installmentValue))}
                        </span>
                    </div>
                    <button className="send">Adicionar</button>
                </form>
            </Modal>
        </>
    );
};
