import styled from "styled-components";
import { darken, transparentize } from "polished";

const textColor = (props) => props.theme.colors.text;
var grayColor = (props) => props.theme.colors.gray;

export const TransactionTypeContainer = styled.div`
    margin: 1rem 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
`;

interface IRadioBoxProps {
    isactive: string;
    activecolor: "green" | "red";
}

const colors = {
    green: "#33CC95",
    red: "#E52E4D",
};

export const RadioBox = styled.button<IRadioBoxProps>`
    height: 3rem;
    border: 1px solid #d7d7d7;
    border-radius: 0.25rem;

    background: ${(props) => (props.isactive === "true" ? transparentize(0.2, colors[props.activecolor]) : "transparent")};

    display: flex;
    align-items: center;
    justify-content: center;

    transition: border-color 0.2s;
    cursor: pointer;

    &:hover {
        border-color: ${darken(0.1, "#d7d7d7")};
    }

    svg {
        font-size: 1.5rem;
        color: ${(props) => (props.isactive === "true" ? "white" : textColor)};

        &.income {
            color: ${(props) => (props.isactive === "true" ? "white" : colors.green)};
        }

        &.expense {
            color: ${(props) => (props.isactive === "true" ? "white" : colors.red)};
        }
    }

    span {
        display: inline-block;
        margin-left: 1rem;
        font-size: 1rem;
        font-family: "Roboto", sans-serif;
        color: ${(props) => (props.isactive === "true" ? "white" : textColor)};
    }
`;
