import { api } from "./InstanceAxios";

export interface Category {
    id: number;
    title: string;
    type: "income" | "expense";
    deleted: boolean;
    createdAt: string;
    updatedAt: string;
}

interface CategorySave {
    title: string;
    type: "income" | "expense";
}

export async function getCategories(): Promise<Category[]> {
    let accounts = [];
    const token = localStorage.getItem("accessToken");

    await api
        .get(`categories`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(async (response) => {
            accounts = await response.data;
        })
        .then((error) => {
            return error;
        });

    return accounts;
}

export async function saveCategory(account: CategorySave) {
    let accountAdded: Category;
    const token = localStorage.getItem("accessToken");

    await api
        .post("categories", account, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(async (response) => {
            accountAdded = response.data;
        })
        .then((error) => {
            return error;
        });

    return accountAdded;
}

export async function updateCategory(id: number, data: {}): Promise<any> {
    let accountUpdated = [];
    const token = localStorage.getItem("accessToken");

    delete data["id"];

    await api
        .patch(
            "categories",
            {
                id: id,
                data: data,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )
        .then(async (response) => {
            accountUpdated.push(response.data.data);
        })
        .then((error) => {
            return error;
        });

    return accountUpdated;
}

export async function deleteCategory(id: number) {
    const token = localStorage.getItem("accessToken");

    await api
        .delete("categories", {
            data: {
                id: id,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error;
        });
}
